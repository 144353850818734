<template>
  <div class="cms-input-settings">
    <!-- <ui-input
      xxxxv-if="showTypePicker"
      type="select"
      label="Tipo"
      :options="types"
      v-model="block.props.type"
      @input="input"
    ></ui-input> -->

    <ui-field label="Tipo">
      <select
        class="ui-native"
        v-model="block.props.type"
        @change="input"
      >
        <option
          v-for="type in types"
          :key="type.value"
          :value="type.value"
        >{{ type.text}}</option>
      </select>
    </ui-field>

    <!-- <ui-field
      v-if="block.props.type == 'select'"
      label="Opciones"
    >
      <options-editor
        v-model="block.props.options"
        @input="input"
      ></options-editor>
    </ui-field> -->
    <ui-field
      v-if="block.props.type == 'date' || block.props.type == 'timestamp'"
      label="Selector de hora"
    >
      <select
        class="ui-native"
        v-model="block.props.time"
        @change="input"
      >
        <option :value="undefined">Desactivado</option>
        <option value="12">AM/PM</option>
        <option value="24">24 horas</option>
      </select>
      <!-- !!! se debe usar @change en vez de @input, de lo contrario el cambio no es reactivo (?!) -->
      <!--
      <input
        type="checkbox"
        v-model="block.props.time"
        @change="input"
      />
      -->
    </ui-field>

    <ui-input
      type="text"
      label="Etiqueta"
      v-model="block.props.label"
      @input="input"
    ></ui-input>

    <ui-input
      type="text"
      label="Subtexto"
      v-model="block.props.message"
      @input="input"
    ></ui-input>

    <ui-input
      type="text"
      label="Placeholder"
      v-model="block.props.placeholder"
      @input="input"
    ></ui-input>
  </div>
</template>

<script>
import BlockModel from '@/modules/cms/mixins/BlockModel.js';
import { UiField, UiInput } from '@/modules/ui/components';
import OptionsEditor from './SelectOptionsEditor.vue';

export default {
  name: 'cms-input-settings',
  mixins: [BlockModel],
  components: { UiField, UiInput, OptionsEditor },

  props: {
    showTypePicker: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      types: [
        {
          icon: 'mdi:form-textbox',
          text: 'Text',
          value: 'text',
        },
        {
          icon: 'mdi:form-textarea',
          text: 'Textarea',
          value: 'textarea',
        },
        {
          icon: 'mdi:form-dropdown',
          text: 'Select',
          value: 'select',
        },
        {
          icon: 'mdi:checkbox-blank-outline',
          text: 'Checkbox',
          value: 'checkbox',
        },
        {
          icon: 'mdi:calendar',
          text: 'Fecha',
          value: 'timestamp',
        },
        {
          icon: 'mdi:numeric',
          text: 'Número',
          value: 'number',
        },
        {
          icon: 'mdi:form-textbox-password',
          text: 'Contraseña',
          value: 'password',
        },
        {
          icon: 'mdi:paperclip',
          text: 'Archivo',
          value: 'file',
        },
      ],
    };
  },
};
</script>